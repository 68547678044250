import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCustomNavigate } from '../utils/customNavigation';


const Footer = () => {
  const customNavigate = useCustomNavigate();
  return (
    <footer className='' style={{ backgroundColor: '#FCDDD4' }}>
      <div className='g-0'>
        <Row className="align-items-center g-0">
          <Col md={6} sm={12} className="g-0">
            <Image src='images/nomanejes.png' alt="Logo de la empresa" className="my-3 logo-alienado" style={{height:'30%', width: '25%' }} />
            <Image src='images/footer.png' alt='bg' className='img-fluid ps-md-5 my-4 my-md-0'/>
          </Col>
          <Col md={6} sm={12} className='g-0'>
          <ul className='texto-derecha list-unstyled text-decoration g-0 mt-3 '>
                <li>
                    <h2>Menu</h2>  
                </li>
                <li><button onClick={() =>{ customNavigate('#form')}} className='btn border border-0 my-md-0'>Inicio</button></li>
                <li><button onClick={() =>{customNavigate('#about-us')}} className='btn border border-0'>Sobre Nostros</button></li>
                <li><button href="/terms" className='btn border border-0'>Terminos & Condiciones</button></li>
                <li>
                <a href="https://www.instagram.com/no_manejes/" target="_blank" rel="noreferrer" className="ms-2">
                    <img width="24" alt="Instagram logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png" height="24" />
                </a>
                </li>
                <button  onClick={() =>{ customNavigate('#form')}}  className=" btn text-white w-50 bg-dark border-0 mt-3 ">
                  Cotizar
                </button>
            </ul>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
