import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navi from './components/Nav';
import Home from './components/Home';
import Info from './components/Info';
import Ofrecemos from './components/Ofrecemos'
import Steps from './components/Steps';
import Review from './components/Review';
import Faqs from './components/FAQ';
import Contactos from './components/contactanos';
import Footer from './components/Footer';
import Informacion from './components/Informacion';
import TextPage from './components/TermsConditions';
import Gracias from './components/Gracias';
import QuoteForm from './components/FormQuote';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.bitrix24.com/b2514537/crm/site_button/loader_12_zymadx.js?' + (Date.now() / 60000 | 0);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
      <Router>
      <div className="App">
        <Navi/>
        <Routes>
          <Route path="/" element={<>
          <Home/>
          <div className='d-block d-md-none px-4 mt-5'> 
            <QuoteForm>
            </QuoteForm>
          </div>
          <Info/>
          <Ofrecemos/>
          <Steps/>
          <Review/>
          <Faqs/>
          <Contactos/>
          </>} />
          <Route path='/info' element={<Informacion/>}/>
          <Route path='/terms'element={<TextPage/>}/>
          <Route path='/gracias'element={<Gracias/>}/>
  </Routes>
  <Footer/>
</div>
</Router>
);
}

export default App;
